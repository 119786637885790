import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Image from '@components/utility/Image';
import Cta from '@components/content/Cta';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';

// styles
import './styles.scss';

const FunctionAndBehaviour = () => {
	return (
		<Layout>
			<Seo title='Unmet Need for DMD Patients | EMFLAZA® (deflazacort)' description='Boys with DMD may have unique functional & emotional needs. Learn more about the impact of current DMD treatments and the options available. View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--function">
				<Hero
					heroImage='hero-1-0.png'
					heroImageMobile='hero-1-0-mobile.png'
					breadcrumbs={['Unmet need']}
					title={
						<span>
							<span className='block text-2xl leading-8 normal-case md:text-3xl'>Although prednisone has been common in treating DMD,</span>
							<span>It may not answer the needs of today's child<sup className="text-sm font-medium md:text-3xl">5,6</sup></span>
						</span>
					}
					addedClass='py-2'
				/>

				<div className="px-8 xl:max-w-4xl text-emflaza-blue-600 md:px-0">
					<div className="flex flex-wrap items-center gap-10 md:ite md:gap-0 md:justify-center">
						<p className='flex-1 max-w-[300px]'>Boys with DMD may have unique functional and emotional needs; these considerations should be noted when choosing a treatment option.<sup>7</sup></p>
						<div className='flex-auto md:flex-1 max-w-[300px]'>
							<Image imageName='functional-emotional-wellbeing.png' alt="Functional & emotional well-being"></Image>
						</div>
					</div>

					{/* SECTION BLUE BG START */}
					<div className='relative'>
						{/* BG IMAGE START */}
						<div className="absolute section-bg -left-8 -right-8 md:h-[375px] md:-right-7 md:left-auto xl:h-auto xl:left-0 -top-12 md:-top-24 xl:right-auto z-negative">
							<Image imageName='section-1-0-bg.png' imageNameMobile='section-1-0-bg-mobile.png' alt=""></Image>
						</div>
						{/* BG IMAGE END */}

						{/* INNER CONTENT START */}
						<div className='flex flex-col flex-wrap justify-center md:flex-row xl:justify-end md:gap-2 xl:gap-14 my-36 md:my-48'>
							<h2 className='font-gotham font-black text-xl md:text-4xl uppercase max-w-[375px]'>
								Behavioral changes can be devastating
								<sup className='superscript'>5,7</sup>
							</h2>
							<div className='mt-6 md:mt-0 max-w-[350px]'>
								<p>Boys, adolescents, and parents may notice changes<sup>5,7</sup>:</p>

								<ul className='mt-5 bulleted-list'>
									<li className='flex gap-4 my-3'>
										<p className='flex-1'>
											Increased <span className='font-bold uppercase highlightedText'>irritability</span> and <span className='font-bold uppercase highlightedText'>aggression</span> are common with corticosteroid treatment
											and can be devastating for both patients and caregivers at a time when the here and
											now is everything.<sup>5,7</sup>
										</p>
									</li>
									<li className='flex gap-4 my-3'>
										<p className='flex-1'>
											This, along with <span className='font-bold uppercase highlightedText'>weight gain</span>, can significantly impact patients’
											functional <span className='font-bold uppercase highlightedText'>and emotional</span>  <span className="whitespace-nowrap">well-being.<sup>7-9</sup></span>
										</p>
									</li>
								</ul>
							</div>
						</div>
						{/* INNER CONTENT END */}
					</div>

					<div className="flex flex-wrap mt-20 mb-8 space-y-5 md:space-y-0 md:space-x-5 lg:justify-center xl:justify-start 2xl:pr-24">
						<Cta ctaUrl='/tolerability/weight-behavior' iconUrl={'cta-person-icon.png'} ctaText='Effects on weight & behavior' addedClass='cta flex-1' iconAddedClass='max-w-[67px]'/>
						<Cta ctaUrl='/efficacy/muscle-function' iconUrl={'cta-pill-icon.png'} ctaText='See the deflazacort difference' addedClass='cta flex-1' iconAddedClass='max-w-[60px]'/>
					</div>

				</div>
			</div>
		</Layout>
	);
};

export default FunctionAndBehaviour;
